import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextPiece from '../../components/TextPiece';
import corporateImg from '../../images/corporate.svg';
import disciplineImg from '../../images/discipline.svg';
import ideaImg from '../../images/idea.svg';
import onlineImg from '../../images/online.svg';
import performanceImg from '../../images/performance.svg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space spacing-50">
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('WhatQualityIsTP1Title')}
            paragraphs={[t('WhatQualityIsTP1Para')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={onlineImg}
            className="img img-w-300 block m-auto"
            alt={t('WhatQualityIsTP1ImgAlt')}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <img
            src={corporateImg}
            className="img img-w-300 m-auto d-none d-lg-block"
            alt={t('WhatQualityIsTP2ImgAlt')}
          />
        </Col>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('WhatQualityIsTP2Title')}
            paragraphs={[t('WhatQualityIsTP2Para')]}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('WhatQualityIsTP3Title')}
            paragraphs={[t('WhatQualityIsTP3Para')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={ideaImg}
            className="img img-w-300 m-auto d-none d-lg-block pt-5"
            alt={t('WhatQualityIsTP3ImgAlt')}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <img
            src={disciplineImg}
            className="img img-w-300 m-auto d-none d-lg-block pt-5"
            alt={t('WhatQualityIsTP4ImgAlt')}
          />
        </Col>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('WhatQualityIsTP4Title')}
            paragraphs={[t('WhatQualityIsTP4Para')]}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('WhatQualityIsTP5Title')}
            paragraphs={[t('WhatQualityIsTP5Para')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={performanceImg}
            className="img img-w-300 block m-auto pt-5"
            alt={t('WhatQualityIsTP5ImgAlt')}
          />
        </Col>
      </Row>
    </Container>
  );
};
