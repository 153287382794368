import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import PoikkeamaKustannusRaporttiImage from '../../components/images/PoikkeamaKustannusRaportti';
import QMCloudsKeskeisimmatDokumenttipohjatImage from '../../components/images/QMCloudsKeskeisimmatDokumenttipohjatImage';
import TextPiece from '../../components/TextPiece';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space spacing-50">
      <Row>
        <Col lg={7}>
          <h2 className="text-blue">{t('BenefitsOfQualitySystemTitle')}</h2>
          <p>{t('BenefitsOfQualitySystemPara')}</p>
          <ContactRequest />
        </Col>
        <Col lg={5} className="mt-4">
          <QMCloudsKeskeisimmatDokumenttipohjatImage className="img-rounded img-shadow" />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('BenefitsOfQualitySystemTP1Title')}
            paragraphs={[t('BenefitsOfQualitySystemTP1Para')]}
          />
        </Col>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('BenefitsOfQualitySystemTP2Title')}
            paragraphs={[t('BenefitsOfQualitySystemTP2Para')]}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('BenefitsOfQualitySystemTP3Title')}
            paragraphs={[t('BenefitsOfQualitySystemTP3Para')]}
          />
        </Col>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('BenefitsOfQualitySystemTP4Title')}
            paragraphs={[t('BenefitsOfQualitySystemTP4Para')]}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('BenefitsOfQualitySystemTP5Title')}
            paragraphs={[t('BenefitsOfQualitySystemTP5Para')]}
          />
        </Col>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('BenefitsOfQualitySystemTP6Title')}
            paragraphs={[t('BenefitsOfQualitySystemTP6Para')]}
          />
          <ContactRequest />
        </Col>
      </Row>
      <Row>
        <Col>
          <PoikkeamaKustannusRaporttiImage />
        </Col>
      </Row>
    </Container>
  );
};
