import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import LinkTo from '../../components/LinkTo';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={6}>
          <h2 className="text-blue">
            {t('WhatIsQualityManagementSystemTitle1')}
          </h2>
          <p>{t('WhatIsQualityManagementSystemPara1')}</p>
          <LinkTo
            url={`${urls.softwareUrl}#features`}
            text={t('LinkToFeatures')}
          />
          <div className="mb-4" />
        </Col>
        <Col lg={6}>
          <h2 className="text-blue">
            {t('WhatIsQualityManagementSystemTitle2')}
          </h2>
          <p>{t('WhatIsQualityManagementSystemPara2')}</p>
          <ContactRequest />
        </Col>
      </Row>
    </Container>
  );
};
