import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsTabletDokumenttipohjatImg from '../../components/images/QMCLoudsTabletDokumenttipohjatImg';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage className="hero">
      <Container>
        <Row>
          <Col lg={7}>
            <h1>{t('QualityHeroTitle')}</h1>
            <p>{t('QualityHeroPara1')}</p>
            <p>{t('QualityHeroPara2')}</p>
          </Col>
          <Col lg={5} className="mt-5">
            <QMCloudsTabletDokumenttipohjatImg />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
