import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import LinkTo from '../../components/LinkTo';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row>
          <Col className="">
            <h2 className="text-white">{t('UtilizationDocumentsTitle')}</h2>
            <p>{t('UtilizationDocumentsPara1')}</p>
            <p>{t('UtilizationDocumentsPara2')}</p>
            <p>{t('UtilizationDocumentsPara3')}</p>
            <LinkTo
              url={`${urls.qualityUrl}#documents`}
              text={t('CheckoutDocumentTemplates')}
              hoverWhite
            />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
