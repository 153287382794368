import React from 'react';

interface Props {
  title: string;
  subTitle?: string;
  paragraphs: string[];
  isWhite?: boolean;
  icon?: string;
  hTag: string;
  subHTag?: string;
}

export default (props: Props) => {
  const {
    title,
    subTitle,
    paragraphs,
    isWhite = false,
    icon,
    hTag,
    subHTag,
  } = props;
  let whiteClass = '';

  if (isWhite === true) {
    whiteClass = 'text-white';
  }

  const header1 = React.createElement(
    hTag,
    {
      className: `text-blue ${whiteClass}`,
    },
    title
  );

  let header2;
  if (subHTag) {
    header2 = React.createElement(
      subHTag,
      {
        className: whiteClass,
      },
      subTitle
    );
  }

  return (
    <div className="text-piece-component">
      {icon ? (
        <img src={icon} className="img img-w-50 mb-3" alt="SVG iconi" />
      ) : null}
      {header1}
      <div className="line blue-line"></div>
      {header2}
      {paragraphs.map((paragraph) => (
        <p key={paragraph.substring(0, 10)} className={whiteClass}>
          {paragraph}
        </p>
      ))}
    </div>
  );
};
