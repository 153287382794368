import React from 'react';
import DocumentTemplates from '../components/DocumentTemplates';
import Layout from '../components/layout';
import QualityManualRequestWithBg from '../components/QualityManualRequestWithBg';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import BenefitsOfQualitySystem from '../pages-partials/quality/BenefitsOfQualitySystem';
import HowToBuildSystem from '../pages-partials/quality/HowToBuildSystem';
import NewIso9001 from '../pages-partials/quality/NewIso9001';
import QualityHero from '../pages-partials/quality/QualityHero';
import UtilizationDocuments from '../pages-partials/quality/UtilizationDocuments';
import WhatIsQualityManagementSystem from '../pages-partials/quality/WhatIsQualityManagementSystem';
import WhatQualityIs from '../pages-partials/quality/WhatQualityIs';

const QualityPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('qualitySeoTitle')}
      description={t('qualitySeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/quality"
      lang={locale}
    />
    <QualityHero />
    <WhatIsQualityManagementSystem />
    <DocumentTemplates />
    <NewIso9001 />
    <WhatQualityIs />
    <UtilizationDocuments />
    <BenefitsOfQualitySystem />
    <QualityManualRequestWithBg />

    <HowToBuildSystem />
  </Layout>
);

export default withI18next({ ns: 'common' })(QualityPage);
