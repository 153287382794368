import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage2 from '../../components/images/BackgroundImage2';
import RiskitImage from '../../components/images/RiskitImage';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage2>
      <Container className="container-space text-white">
        <Row>
          <Col lg={12}>
            <h2 className="text-white">{t('NewIso9001Title')}</h2>
            <ul>
              <li>{t('NewIso9001List1')}</li>
              <li>{t('NewIso9001List2')}</li>
              <li>{t('NewIso9001List3')}</li>
              <li>{t('NewIso9001List4')}</li>
              <li>{t('NewIso9001List5')}</li>
            </ul>
            <p>{t('NewIso9001Para')}</p>
            <RiskitImage />
          </Col>
        </Row>
      </Container>
    </BackgroundImage2>
  );
};
