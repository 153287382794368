import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import QMCloudsDokumenttiMalliImg from '../../components/images/QMCloudsDokumenttiMalliImg';
import QMCloudsMobileBgtImg from '../../components/images/QMCloudsMobileBgImg';
import QualityManualRequest from '../../components/QualityManualRequest';
import TextPiece from '../../components/TextPiece';
import decisionImg from '../../images/decision.svg';
import onlineImg from '../../images/online.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space spacing-50">
      <Row>
        <Col lg={12}>
          <h2 className="mb-5 text-blue">{t('HowToBuildSystemH2')}</h2>
        </Col>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            subHTag="h4"
            title={t('HowToBuildSystemTitle1')}
            subTitle={t('HowToBuildSystemSubTitle1')}
            paragraphs={[t('HowToBuildSystemPara1')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={onlineImg}
            className="img img-w-300 block m-auto"
            alt={t('HowToBuildSystemImgAlt1')}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mt-5">
          <QMCloudsDokumenttiMalliImg className="d-none d-lg-block img-w-300 block m-auto" />
        </Col>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            subHTag="h4"
            title={t('HowToBuildSystemTitle2')}
            subTitle={t('HowToBuildSystemSubTitle2')}
            paragraphs={[t('HowToBuildSystemPara2')]}
          />
          <QualityManualRequest />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            subHTag="h4"
            title={t('HowToBuildSystemTitle3')}
            subTitle={t('HowToBuildSystemSubTitle3')}
            paragraphs={[t('HowToBuildSystemPara3')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={decisionImg}
            className="img img-w-300 block m-auto d-none d-lg-block"
            alt={t('HowToBuildSystemImgAlt3')}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <QMCloudsMobileBgtImg className="img-w-350 block m-auto d-none d-lg-block" />
        </Col>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            subHTag="h4"
            title={t('HowToBuildSystemTitle4')}
            subTitle={t('HowToBuildSystemSubTitle4')}
            paragraphs={[t('HowToBuildSystemPara4')]}
          />
          <ContactRequest />
        </Col>
      </Row>
    </Container>
  );
};
